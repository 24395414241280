.fixed-button {
  z-index: 100;
  position: fixed;
  right: 1.5rem;
  font-size: 1rem;
  line-height: 1.5rem;
  padding: 0.5rem 0.625rem;
  color: $global-font-secondary-color;
  background-color: $header-background-color;
  @include border-radius(1.25rem);
  @include transition(color 0.4s ease);

  @include blur;

  &:hover,
  &:active {
    color: $global-font-color;
    cursor: pointer;
  }

  &:active,
  &:focus,
  &:hover {
    outline: none;
  }

  [data-theme='dark'] & {
    color: $global-font-secondary-color-dark;
    background-color: $header-background-color-dark;

    &:hover,
    &:active {
      color: $global-font-color-dark;
    }
  }
}

.back-to-top {
  bottom: 1.5rem;
}

.view-comments {
  bottom: 4.5rem;
}
