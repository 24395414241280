.post-footer {
  margin: 2rem 0;

  &:has(~.post-reward) {
    margin-bottom: 0;
  }

  .post-info {
    border-bottom: 1px solid $global-border-color;
    padding: 1rem 0 0.3rem;

    [data-theme='dark'] & {
      border-bottom: 1px solid $global-border-color-dark;
    }

    .post-info-line {
      display: flex;
      justify-content: space-between;

      .post-info-mod {
        font-size: 0.8em;
        color: $global-font-secondary-color;

        [data-theme='dark'] & {
          color: $global-font-secondary-color-dark;
        }

        @include link(false, false);
      }

      .post-info-license {
        font-size: 0.8em;
        color: $global-font-secondary-color;

        [data-theme='dark'] & {
          color: $global-font-secondary-color-dark;
        }

        @include link(false, false);
      }

      .post-info-md {
        font-size: 0.8rem;
        width: fit-content;

        @include link(false, false);

        >span:not(:first-child)::before {
          content: '|';
          margin-left: 0.25rem;
          margin-right: 0.25rem;
        }
      }

      .post-info-share {
        a * {
          vertical-align: text-bottom;
        }
      }
    }
  }

  .post-info-more {
    padding: 0.3rem 0 1rem;
    display: flex;
    justify-content: space-between;
    font-size: 0.9rem;
  }

  .post-tags {
    max-width: 65%;

    * {
      display: inline;
    }

    @include link(true, true);

    .post-tag:not(:last-child)::after {
      content: ',';
      margin-right: 0.25rem;
    }
  }

  .post-nav {
    display: flex;
    justify-content: space-between;
    gap: 1.5rem;

    .post-nav-item {
      flex: 1;
      font-size: 1rem;
      font-weight: 600;
      @include transition(all 0.3s ease-out);

      &[rel='next'] {
        text-align: right;
      }

      &[rel='prev']:hover {
        @include transform(translateX(-4px));
      }

      &[rel='next']:hover {
        @include transform(translateX(4px));
      }
    }
  }
}
